import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Alexander Sitzman </span>
            from <span className="purple"> Seattle, Washington.</span>
            <br />
            <br/>
            I am currently working on completing my certifications in Linux System Administration, Cloud Administration and Kubernetes
            <br />
            <br/>
            I have a Bachelor's Degree in Computer Science from Western Washington University
            <br />
              <br />
              I am deeply passionate about continuous learning and apply this mindset to every aspect of my life. 
              I am always seeking new opportunities to expand my skillset and explore new concepts, ensuring that I am constantly growing both personally and professionally.
               <br/>
              <br/>
               In my professional and personal endeavors, 
              I've always approached challenges with a problem-solving mindset. 
              Whether faced with academic hurdles or complex coding tasks, 
              I view each obstacle as an opportunity for growth and learning. 
              My experiences navigating the complexities of computer systems 
              and operating environments have strengthened my resolve and sharpened 
              my problem-solving skills. With unwavering determination and resilience, 
              I tackle each challenge head-on, driven by a passion for discovery and innovation.
              <br/>
              <br/>
          </p>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
