import React from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiNetbsd,
  SiRaspberrypi,
  SiArduino,
  SiXcode,
  SiNotion,
  SiObsidian,
  SiMicrosoftteams,
  SiJupyter,
  SiAzuredevops,
  SiGooglecolab,
  SiDocker,
  SiGoogleanalytics,
  SiFirebase,
  SiShopify,
  SiKubernetes,
  SiLinux
} from "react-icons/si";

function Toolstack() {
  const tools = [

    { icon: <SiKubernetes/>, name:"Kubernetes"},
    { icon: <SiVisualstudiocode />, name: "Visual Studio Code" },
    { icon: <SiRaspberrypi />, name: "Raspberry Pi" },
    { icon: <SiArduino />, name: "Arduino" },
    { icon: <SiObsidian/>, name: "Obsidian"},
    { icon: <SiJupyter/>, name: "Jupyter"},
    { icon: <SiAzuredevops/>, name: "Azure"},
    { icon: <SiDocker/>, name: "Docker"},
    {icon: <SiGoogleanalytics/>, name: "Google Analytics"},
    {icon: <SiVercel/>, name:"Vercel"},
    { icon: <SiLinux/>, name:"Linux"},
    {icon: <SiFirebase/>, name:"Firebase"},



  ];

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {tools.map((tool, index) => (
        <Col xs={4} md={2} className="tech-icons" key={index}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{tool.name}</Tooltip>}
          >
            <div>{tool.icon}</div>
          </OverlayTrigger>
        </Col>
      ))}
    </Row>
  );
}

export default Toolstack;
